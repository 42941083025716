:root {
  --color1: #ee7752;
  --color2: #e73c7e;
  --color3: #23a6d5;
  --color4: #23d5ab;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

body {
  background: linear-gradient(-45deg, var(--color1), var(--color2), var(--color3), var(--color4));
  background-size: 400% 400%;
  animation: gradient 12s ease infinite;
  padding-top: 60px; /* Adjust based on header height */
  padding-bottom: 40px;
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* 🔥 Mobile Optimizations 🔥 */
@media (max-width: 768px) {
  body {
    background-size: 200% 200%;
    animation: gradient 18s ease infinite; /* Reduce animation speed on mobile */
  }
}

.dark-mode {
  background: linear-gradient(-45deg, #111, #222, #333, #444);
  color: white;
}

