:root {
  --font-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-stack);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* 🔥 Mobile Optimizations 🔥 */
@media (max-width: 768px) {
  body {
    font-size: 16px; /* Adjust text size */
    padding: 10px;
  }
  
  h1 {
    font-size: 1.8rem; /* Scale down titles */
  }

  p {
    font-size: 1rem;
  }
}
